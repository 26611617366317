<template>
  <div ref="mapWrapper" class="map-wrapper">
    <div class="map-star-cover"></div>
    <div class="map-star" ref="mapStar">
      <inline-svg
        :src="require(`../../assets/images/gallery1/map-1-star.svg`)"
        @loaded="svgLoaded($event)"
      />
    </div>
    <div id="map" ref="mapImage" class="map-base">
      <inline-svg
        :src="require(`../../assets/images/gallery1/map-0.svg`)"
        @loaded="svgLoaded($event)"
      />
    </div>
    <div class="map-star-bg" ref="mapBg"></div>
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";

// import panzoom from "panzoom";

export default {
  data() {
    return {
      svgTotalLoaded: 0,
      mapInstance: null,
      mouse: { x: 0, y: 0 },
      mouseOrigin: { x: 0.5, y: 0.5 },
    };
  },
  mounted() {
    const vm = this;
    this.$store.commit("updateCurrentMap", 1);
    // this.mapInstance = panzoom(this.$refs.mapImage, {
    //   zoomSpeed: 0.05,
    //   transformOrigin: { x: 0.25, y: 0.5 },
    //   minZoom: .8,
    //   maxZoom: 4,
    // });
  },
  methods: {
    mouseMove(e) {
      // const vm = this;
      // this.mouse = {
      //   x: e.clientX,
      //   y: e.clientY,
      // };
      // this.mouseOrigin = {
      //   x: Math.round(e.clientX / window.innerWidth),
      //   y: Math.round(e.clientY / window.innerHeight),
      // };
      // this.mapInstance.setTransformOrigin(this.mouseOrigin);
    },
    svgLoaded(e) {
      this.svgTotalLoaded++;
      if (this.svgTotalLoaded == 2) {
        const tl = new gsap.timeline();
        tl.set(".map-star-cover", {
          opacity: 0,
        });
        tl.set(this.$refs.mapBg, {
          scale: 2,
          opacity: 0,
        });
        tl.set(this.$refs.mapImage, {
          scale: 2,
          x: "-28vw",
          y: "50vh",
          opacity: 0,
        });
        tl.set(this.$refs.mapStar, {
          y: "55vh",
          scale: 2.5,
          opacity: 0,
        });
        tl.set("#coutries", {
          opacity: 0,
          display: "none",
        });
        tl.to(
          this.$refs.mapBg,
          {
            duration: 3,
            scale: 1,
            opacity: 1,
            ease: Power2.easeOut,
          },
          "0"
        );
        tl.to(
          ".map-star-cover",
          {
            opacity: 1,
            duration: 1,
          },
          "0"
        );
        tl.to(
          this.$refs.mapImage,
          {
            duration: 3,
            y: "25vh",
            opacity: 1,
            ease: Power2.easeInOut,
          },
          "0"
        );
        tl.to(
          this.$refs.mapStar,
          {
            duration: 3,
            y: "40vh",
            scale: 2,
            opacity: 1,
            ease: Power2.easeInOut,
            onComplete: () => {
              if (this.$route.name == "Map-1") {
                this.$store.commit("updateMapDialog", true);
              }
            },
          },
          "-=2.8"
        );
      }
    },
    mouseOver() {},
  },
};
</script>

<style scoped>
.map-star-cover {
  position: fixed;
  top: 0;
  width: 100%;
  height: 20vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(12, 11, 48, 1) 100%
  );
}
#coutries {
  opacity: 0;
  display: none !important;
}
.map-star {
  position: absolute;
  width:100vw;
  height:calc(100vh - 40vh);

  z-index: 10;
  opacity: 0;
  top: 0;
}
.map-star-bg {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/gallery1/map-1-bg.jpg") center center;
  background-size: cover;
  position: fixed;
  mix-blend-mode: multiply;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
